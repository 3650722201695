<!--
<script setup>
import { computed, onUnmounted, ref, watch } from "vue";
import { fbauth } from "@/firebase";
import axios from "axios";
import { config } from '@/config';

const ausgeliehen = ref([]);
const neuVerfuegbar = ref([]);
const releases = ref([]);
const haushaltsbuch = ref([]);
const logMessages = ref([]);
const status = ref([])
let authToken = null;

const weekday = ["Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag"];

const formatDateTime = (dateTimeString, isWithTime) => {
  let options = {};
  if (isWithTime) {
    options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    };
  } else {
    options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    };
  }

  const date = new Date(dateTimeString);
  return date.toLocaleDateString("de-DE", options);
};


const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", options);
};


// Countdown logic
const countdownDate = new Date("2024-11-01");
const today = new Date();

const daysUntilDate = computed(() => {
  const timeDifference = countdownDate.getTime() - today.getTime();
  return Math.ceil(timeDifference / (1000 * 3600 * 24));
});


const maxTimestamp = computed(() => {
  const timestamps = ausgeliehen.value
    .map((rw21) => new Date(rw21.Timestamp).toISOString())
    .filter((timestamp) => /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(timestamp));

  if (timestamps.length > 0) {
    const maxTimestamp = timestamps.reduce((a, b) => (a > b ? a : b));
    return maxTimestamp;
  } else {
    return "No entries available";
  }
});


const fetchData = async () => {
  const auth = fbauth;

  if (auth.currentUser) {
    try {
      authToken = await auth.currentUser.getIdToken();

      const rw21Response = await axios.get(  `${config.apiUrl}rw21/ausgeliehen`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        withCredentials: true
      });

      ausgeliehen.value = rw21Response.data.map((ausgeliehen) => ({
        ...ausgeliehen,
        ImageURL: ausgeliehen.ImageURL || null
      })).sort((a, b) => a.Frist.localeCompare(b.Frist));

      const rw21NeuResponse = await axios.get(`${config.apiUrl}/rw21/neu`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        withCredentials: true
      });

      neuVerfuegbar.value = rw21NeuResponse.data.map((neuVerfuegbar) => ({
        ...neuVerfuegbar,
        ImageURL: neuVerfuegbar.ImageBase64 || null
      })).sort((a, b) => a.Mediengruppe.localeCompare(b.Mediengruppe));



      const releasesResponse = await axios.get(`${config.apiUrl}/releases`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });



      releases.value = releasesResponse.data.map((release) => ({
        ...release,
        Wochentag: weekday[new Date(release.ReleaseDate).getDay()],
        ReleaseDate: formatDateTime(release.ReleaseDate, false)
      }))
        .filter(release => release.Gesehen !== true)
        .filter(release => release.TageVerbleibend <= 14 && release.TageVerbleibend >= -7)
        .sort((a, b) => new Date(a.ReleaseDate) - new Date(b.ReleaseDate));


      const hbResponse = await axios.get(`${config.apiUrl}/haushaltsbuch/nichtErhalten`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      haushaltsbuch.value = hbResponse.data.sort((a, b) => {
        // Handling null values for Vorbestellung
        if (a.Vorbestellung === null && b.Vorbestellung === null) {
          // If both are null, sort by Datum
          return new Date(a.Datum) - new Date(b.Datum);
        } else if (a.Vorbestellung === null) {
          // If a.Vorbestellung is null, sort it after b.Vorbestellung
          return 1;
        } else if (b.Vorbestellung === null) {
          // If b.Vorbestellung is null, sort it before a.Vorbestellung
          return -1;
        } else {
          // Sort by Vorbestellung dates in ascending order
          const vorbestellungDateComparison = new Date(a.Vorbestellung) - new Date(b.Vorbestellung);

          // If Vorbestellung dates are equal, then compare Datum
          if (vorbestellungDateComparison === 0) {
            return new Date(a.Datum) - new Date(b.Datum);
          }

          return vorbestellungDateComparison;
        }
      });

      const logResponse = await axios.get(`${config.apiUrl}/log`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      logMessages.value = logResponse.data.filter(logEntry => logEntry.level === "ERROR" && (Date.now() - new Date(logEntry.timestamp).getTime())<7*24*60*60*1000 );
      logMessages.value = logMessages.value.map((logEntry) => ({
        ...logEntry,
        timestamp_neu: formatDate(logEntry.timestamp)
      }));


      // Ensure that all entries have valid timestamps before sorting
      logMessages.value = logMessages.value.filter(entry => entry.timestamp);
      logMessages.value.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      console.log (logMessages.value);





      const statusResponse = await axios.get(`${config.apiUrl}/log/status?pass=pass`);

      status.value = statusResponse.data.map((statusEntry) => ({
        ...statusEntry,
        Timestamp: formatDate(statusEntry.Timestamp)
      }));

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User is not authenticated");
  }
};

const isDueSoon = (dueDate) => {
  const today = new Date();
  const dueDateTime = new Date(dueDate);
  const differenceInTime = dueDateTime.getTime() - today.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays <= 7;
};

const isDueUrgent = (dueDate) => {
  const today = new Date();
  const dueDateTime = new Date(dueDate);
  const differenceInTime = dueDateTime.getTime() - today.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays <= 3;
};

const unsubscribe = fbauth.onAuthStateChanged(() => {
  fetchData();
});


const formatAmount = (amount) => {
  return amount.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "€";
};

onUnmounted(() => {
  unsubscribe();

});

watch(ausgeliehen, () => {

});

const handleImageError = (event) => {
  console.error("Image loading error:", event);
};

// Methode zur Rückgabe der Hintergrundfarbe basierend auf der Mediengruppe
const getBackgroundColor = (mediengruppe) => {
  if (mediengruppe.startsWith("Buch")) {
    return "#FFEBCD"; // Beispielfarbe für Bücher
  } else if (mediengruppe.startsWith("DVD")) {
    return "#555555";
  } else if (mediengruppe.startsWith("BluRay")) {
    return "#0095d5"; // Beispielfarbe für DVDs und BluRays
  } else if (mediengruppe === "Switch Spiel") {
    return "#ff0000"; // Rot für Switch Spiele
  } else if (mediengruppe === "Playstation Spiel") {
    return "#003087"; // Blau für Playstation Spiele
  } else if (mediengruppe.startsWith("Spiel")) {
    return "#FF5500"; // Beispielfarbe für Spiele
  } else {
    return "transparent"; // Standard-Hintergrundfarbe
  }
};

const getRowClass = (mediengruppe) => {
  if (mediengruppe.endsWith("Comic")) {
    return "table-secondary";
  } else if (mediengruppe.startsWith("Buch")) {
    return "table-light"; // Beispielfarbe für Bücher
  } else if (mediengruppe.startsWith("DVD")) {
    return "table-dark";
  } else if (mediengruppe.startsWith("BluRay")) {
    return "table-info"; // Beispielfarbe für DVDs und BluRays
  } else if (mediengruppe === "Switch Spiel") {
    return "table-danger"; // Rot für Switch Spiele
  } else if (mediengruppe === "PlayStation Spiel") {
    return "table-primary"; // Blau für Playstation Spiele
  } else if (mediengruppe.startsWith("Spiel")) {
    return "table-warning"; // Beispielfarbe für Spiele
  } else {
    return "transparent"; // Standard-Hintergrundfarbe
  }
};


</script>
-->
<script setup>
import { computed, onUnmounted, ref, watch } from "vue";
import { fbauth } from "@/firebase";
import axios from "axios";
import { config } from "@/config";

const ausgeliehen = ref([]);
const neuVerfuegbar = ref([]);
const releases = ref([]);
const haushaltsbuch = ref([]);
const logMessages = ref([]);
const status = ref([]);
let authToken = null;

const weekday = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];

const formatDateTime = (dateTimeString, isWithTime) => {
  let options = {};
  if (isWithTime) {
    options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    };
  } else {
    options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    };
  }

  const date = new Date(dateTimeString);
  return date.toLocaleDateString("de-DE", options);
};

const formatDate = (dateString) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", options);
};

// Countdown logic
const countdownDate = new Date("2024-11-01");
const today = new Date();

const daysUntilDate = computed(() => {
  const timeDifference = countdownDate.getTime() - today.getTime();
  return Math.ceil(timeDifference / (1000 * 3600 * 24));
});

const maxTimestamp = computed(() => {
  const timestamps = ausgeliehen.value
    .map((rw21) => new Date(rw21.Timestamp).toISOString())
    .filter((timestamp) => /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(timestamp));

  if (timestamps.length > 0) {
    const maxTimestamp = timestamps.reduce((a, b) => (a > b ? a : b));
    return maxTimestamp;
  } else {
    return "No entries available";
  }
});

const fetchData = async () => {
  const auth = fbauth;

  if (auth.currentUser) {
    try {
      authToken = await auth.currentUser.getIdToken();

      const [
        rw21Response,
        rw21NeuResponse,
        releasesResponse,
        hbResponse,
        logResponse,
        statusResponse
      ] = await Promise.all([
        axios.get(`${config.apiUrl}rw21/ausgeliehen`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          withCredentials: true
        }),
        axios.get(`${config.apiUrl}/rw21/neu`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          withCredentials: true
        }),
        axios.get(`${config.apiUrl}/releases`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          withCredentials: true
        }),
        axios.get(`${config.apiUrl}/haushaltsbuch/nichtErhalten`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          withCredentials: true
        }),
        axios.get(`${config.apiUrl}/log`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          withCredentials: true
        }),
        axios.get(`${config.apiUrl}/log/status?pass=pass`)
      ]);

      ausgeliehen.value = rw21Response.data.map((ausgeliehen) => ({
        ...ausgeliehen,
        ImageURL: ausgeliehen.ImageURL || null
      })).sort((a, b) => a.Frist.localeCompare(b.Frist));

      neuVerfuegbar.value = rw21NeuResponse.data.map((neuVerfuegbar) => ({
        ...neuVerfuegbar,
        ImageURL: neuVerfuegbar.ImageBase64 || null
      })).sort((a, b) => a.Mediengruppe.localeCompare(b.Mediengruppe));

      releases.value = releasesResponse.data.map((release) => ({
        ...release,
        Wochentag: weekday[new Date(release.ReleaseDate).getDay()],
        ReleaseDate: formatDateTime(release.ReleaseDate, false)
      }))
        .filter(release => release.Gesehen !== true)
        .filter(release => release.TageVerbleibend <= 14 && release.TageVerbleibend >= -7)
        .sort((a, b) => new Date(a.ReleaseDate) - new Date(b.ReleaseDate));

      haushaltsbuch.value = hbResponse.data.sort((a, b) => {
        if (a.Vorbestellung === null && b.Vorbestellung === null) {
          return new Date(a.Datum) - new Date(b.Datum);
        } else if (a.Vorbestellung === null) {
          return 1;
        } else if (b.Vorbestellung === null) {
          return -1;
        } else {
          const vorbestellungDateComparison = new Date(a.Vorbestellung) - new Date(b.Vorbestellung);
          if (vorbestellungDateComparison === 0) {
            return new Date(a.Datum) - new Date(b.Datum);
          }
          return vorbestellungDateComparison;
        }
      });

      logMessages.value = logResponse.data.filter(logEntry =>
        logEntry.level === "ERROR" && (Date.now() - new Date(logEntry.timestamp).getTime()) < 7 * 24 * 60 * 60 * 1000
      ).map((logEntry) => ({
        ...logEntry,
        timestamp_neu: formatDate(logEntry.timestamp)
      })).filter(entry => entry.timestamp)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      status.value = statusResponse.data.map((statusEntry) => ({
        ...statusEntry,
        Timestamp: formatDate(statusEntry.Timestamp)
      }));

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User is not authenticated");
  }
};

const isDueSoon = (dueDate) => {
  const today = new Date();
  const dueDateTime = new Date(dueDate);
  const differenceInTime = dueDateTime.getTime() - today.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays <= 7;
};

const isDueUrgent = (dueDate) => {
  const today = new Date();
  const dueDateTime = new Date(dueDate);
  const differenceInTime = dueDateTime.getTime() - today.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays <= 3;
};

const unsubscribe = fbauth.onAuthStateChanged(() => {
  fetchData();
});

const formatAmount = (amount) => {
  return amount.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "€";
};

onUnmounted(() => {
  unsubscribe();
});

watch(ausgeliehen, () => {
  // Watcher for changes in 'ausgeliehen'
});

const handleImageError = (event) => {
  console.error("Image loading error:", event);
};

// Methode zur Rückgabe der Hintergrundfarbe basierend auf der Mediengruppe
const getBackgroundColor = (mediengruppe) => {
  if (mediengruppe.startsWith("Buch")) {
    return "#FFEBCD"; // Beispielfarbe für Bücher
  } else if (mediengruppe.startsWith("DVD")) {
    return "#555555";
  } else if (mediengruppe.startsWith("BluRay")) {
    return "#0095d5"; // Beispielfarbe für DVDs und BluRays
  } else if (mediengruppe === "Switch Spiel") {
    return "#ff0000"; // Rot für Switch Spiele
  } else if (mediengruppe === "Playstation Spiel") {
    return "#003087"; // Blau für Playstation Spiele
  } else if (mediengruppe.startsWith("Spiel")) {
    return "#FF5500"; // Beispielfarbe für Spiele
  } else {
    return "transparent"; // Standard-Hintergrundfarbe
  }
};

const getRowClass = (mediengruppe) => {
  if (mediengruppe.endsWith("Comic")) {
    return "table-secondary";
  } else if (mediengruppe.startsWith("Buch")) {
    return "table-light"; // Beispielfarbe für Bücher
  } else if (mediengruppe.startsWith("DVD")) {
    return "table-dark";
  } else if (mediengruppe.startsWith("BluRay")) {
    return "table-info"; // Beispielfarbe für DVDs und BluRays
  } else if (mediengruppe === "Switch Spiel") {
    return "table-danger"; // Rot für Switch Spiele
  } else if (mediengruppe === "PlayStation Spiel") {
    return "table-primary"; // Blau für Playstation Spiele
  } else if (mediengruppe.startsWith("Spiel")) {
    return "table-warning"; // Beispielfarbe für Spiele
  } else {
    return "transparent"; // Standard-Hintergrundfarbe
  }
};
</script>

<template>
  <div class="text-center">
    <div class="countdown-container d-flex justify-content-center align-items-center m-3">
      <div class="countdown-box text-center p-4">
        <h2 class="mb-0">Tage bis Einzug</h2>
        <div class="countdown-number">{{ daysUntilDate }}</div>
      </div>
    </div>

    <div class="container my-5">
      <h2>Status</h2>
      <div v-if="status.length === 0">
        <p>Loading status...</p>
      </div>
      <div v-else>
        <div class="table-responsive">
          <table class="table table-bordered table-responsive table-hover table-sm">
            <thead>
            <tr>
              <th scope="col">Timestamp</th>
              <th scope="col">Program Name</th>
              <th scope="col">Running</th>
              <th scope="col">Letzer Status</th>
              <th scope="col">Letzter Log Eintrag</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(statusEntry, index) in status" :key="index"
                :class="{'table-success': statusEntry.Running, 'table-danger': statusEntry.LetzterStatus === 'ERROR'}">
              <td>{{ statusEntry.Timestamp }}</td>
              <td>{{ statusEntry.Name }}</td>
              <td>{{ statusEntry.Running }}</td>
              <td>{{ statusEntry.LetzterStatus }}</td>
              <td>{{ statusEntry.LetzterLogEintrag }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <h2>RW21 - Ausgeliehen</h2>

      <div class="container">
        <div class="row">
          <div class="col-6 mx-auto">
            <div class="alert alert-info text-center">
              <a href="https://wwwopac.stadt.bayreuth.de/de-de/Meine-Bibliothek" class="link-underline">Meine Bibliothek</a>
            </div>
          </div>
        </div>
      </div>


      <p>Stand: {{ formatDateTime(maxTimestamp, true) }}</p>
      <div v-if="ausgeliehen.length === 0">
        <p>Loading ausgeliehen data...</p>
      </div>
      <div v-else>
        <div class="table-responsive">
          <table class="table table-bordered table-responsive table-hover table-sm">
            <thead>
            <tr>
              <th>Mediengruppe</th>
              <th>Titel</th>
              <th>Bild</th>
              <th>Frist</th>
              <th>Verbleibend</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="ausgeliehen in ausgeliehen"
              :key="ausgeliehen.Title"
              :class="[getRowClass(ausgeliehen.Mediengruppe),{'table-warning': isDueSoon(ausgeliehen.Frist)}, { 'table-danger': isDueUrgent(ausgeliehen.Frist) }]"
              :style="{ backgroundColor: getBackgroundColor(ausgeliehen.Mediengruppe) + ' !important' }"
            >
              <td>{{ ausgeliehen.Mediengruppe }}</td>
              <td>{{ ausgeliehen.Title }}</td>
              <td>
                <img
                  v-if="ausgeliehen.ImageURL"
                  :src="`${ausgeliehen.ImageURL}?token=${authToken}`"
                  alt="Bild"
                  @error="handleImageError"
                />
                <span v-else>No Image</span>
              </td>
              <td>{{ formatDateTime(ausgeliehen.Frist, false) }}</td>
              <td>{{ Math.floor((new Date(ausgeliehen.Frist) - new Date()) / (1000 * 60 * 60 * 24)) + 1 }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="container my-5">
      <h2>RW21 - Neu Verfügbar (letzte Woche)</h2>
      <div class="container">
        <div class="row">
          <div class="col-6 mx-auto">
            <div class="alert alert-info text-center">
              <a href="https://wwwopac.stadt.bayreuth.de/de-de/Meine-Bibliothek" class="link-underline">Meine Bibliothek</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="neuVerfuegbar.length === 0">
        <p>Loading new releases...</p>
      </div>
      <div v-else>
        <div class="table-responsive">
          <table class="table table-bordered table-responsive table-hover table-sm">
            <thead>
            <tr>
              <th>Mediengruppe</th>
              <th>Titel</th>
              <th>Bild</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="rw21 in neuVerfuegbar"
              :key="rw21.Title"
              :class="getRowClass(rw21.Mediengruppe)"
              :style="{ backgroundColor: getBackgroundColor(rw21.Mediengruppe) + ' !important' }"
            >
              <td>{{ rw21.Mediengruppe }}</td>
              <td>
                <a :href="rw21.URL" target="_blank">{{ rw21.Title }}</a>
              </td>
              <td>
                <img
                  v-if="rw21.ImageURL"
                  :src="`${rw21.ImageURL}?token=${authToken}`"
                  alt="Bild"
                  @error="handleImageError"
                />
                <span v-else>No Image</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <h2>Releases der nächsten 14 Tage</h2>
      <div class="table-responsive">
        <table class="table table-bordered table-responsive table-hover table-sm">
          <thead>
          <tr>
            <th>Tage</th>
            <th>Cover</th>
            <th>ReleaseDate</th>
            <th>Titel</th>
            <th>Kategorie</th>
            <th>Platform</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="release in releases" :class="{ 'table-danger': release.TageVerbleibend > 0 }" :key="release.id">
            <td>{{ release.TageVerbleibend }}</td>
            <td>
              <img
                :src="release.ImageURL"
                alt="Cover"
                class="img-fluid"
                style="height: 100%; width:auto; max-height:25vh;"
              />
            </td>
            <td>{{ release.Wochentag + ", " + release.ReleaseDate }}</td>
            <td><a :href="release.URL" target="_blank">{{ release.Titel }}</a></td>
            <td>{{ release.Kategorie }}</td>
            <td>{{ release.Platform }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="container my-5">
      <h2>Nicht erhaltene Einkäufe</h2>
      <div class="row">
        <div class="col-md-12 mb-3" v-for="expense in haushaltsbuch" :key="expense.id">
          <div class="card">
            <div
              v-if="expense.Vorbestellung !== null && new Date(expense.Vorbestellung) < new Date() && expense.Erhalten !== 'Ja'"
              class="redish-layer"
            ></div>
            <div v-else-if="expense.Erhalten !== 'Ja'" class="yellowish-layer"></div>
            <div class="card-header">
              <p class="card-text">
                {{ new Date(expense.Datum).toLocaleDateString("de-DE", {
                weekday: "long",
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
              }) }}
              </p>
            </div>
            <div class="card-body row">
              <div class="col-6">
                <h5 class="card-title">{{ expense.Name }}</h5>
                <p class="card-text">{{ expense.Shop }}</p>
                <span v-if="expense.Vorbestellung !== null" class="me-1 badge text-bg-warning">
                Lieferung bis: {{ new Date(expense.Vorbestellung).toLocaleDateString("de-DE") }}
              </span>
              </div>
              <div :class="'col-6 align-content-center prio-' + expense.PRIO">
                <span>{{ formatAmount(expense.Betrag) }}</span>
              </div>
            </div>
            <div class="card-footer">
            <span class="me-1 badge text-bg-info">
              {{ expense.Oberkategorie }}
            </span>
              <span class="me-1 badge text-bg-info">
              {{ expense.Kategorie }}
            </span>
              <span :class="'me-1 badge text-' + (expense.Erhalten === 'Ja' ? 'bg-success' : 'bg-danger')">
              {{ expense.Erhalten === "Ja" ? "Erhalten" : "Nicht erhalten" }}
            </span>
              <span v-if="expense.Gemeinschaftskonto === 'Ja'" class="me-1 badge text-bg-warning">
              Gemeinschaftskonto
            </span>
              <span v-if="expense.Steuererklärung === 'Ja'" class="me-1 badge text-bg-success">
              Steuererklärung: Ja
            </span>
              <span v-if="expense.Steuererklärung === 'Vielleicht'" class="me-1 badge text-bg-secondary">
              Steuererklärung: Vielleicht
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
.console-box {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  font-family: monospace;
}

.custom-table-row {
  background-color: transparent !important; /* Set background color for the entire row */
}

.countdown-box {
  background-color: #f8f9fa; /* Light background */
  border: 2px solid #dee2e6; /* Light gray border */
  border-radius: 10px; /* Rounded corners */
  width: 200px; /* Fixed width for the box */
}

.countdown-number {
  font-size: 3rem; /* Large font size for the number */
  color: #007bff; /* Bootstrap primary color */
  margin-top: 10px;
}
</style>