<template>
  <div class="container">
    <h1>Anteilige Rabatte</h1>
    <div class="row" v-for="(item, index) in items" :key="index">
      <div class="col-md-4">
        <input v-model="item.name" type="text" class="form-control" placeholder="Name" />
      </div>
      <div class="col-md-4">
        <input v-model.number="item.price" type="number" class="form-control" placeholder="Preis" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <button @click="addItem" class="btn btn-primary">1 Hinzufügen</button>
      </div>
      <div class="col-md-4">
        <input v-model.number="discount" type="number" class="form-control" placeholder="Rabatt" />
      </div>
    </div>
    <div v-if="totalDiscount > 0" class="mt-3">
      <h4>Ergebnis:</h4>
      <div v-for="(item, index) in items" :key="index">
        <p>{{ item.name }} - Rabatt: {{ calculateRelativeDiscount(item) }}€ - Preis:
          {{ calculateResultingPrice(item) }}€</p>
      </div>
    </div>
  </div>


  <div class="container">
    <h1>3 für 2</h1>
    <div class="row" v-for="(item, index) in items3fuer2" :key="index">
      <div class="col-md-3">
        <input v-model="item.name" type="text" class="form-control" placeholder="Name" />
      </div>
      <div class="col-md-3">
        <input v-model.number="item.price" type="number" class="form-control" placeholder="Price" />
      </div>
      <div class="col-md-3">
        <p>{{calculate3fuer2(item)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [{ name: "", price: null }],
      discount: null,

      items3fuer2: [{ name: "", price: null }, { name: "", price: null }, { name: "", price: null }],

    };
  },
  computed: {
    totalDiscount() {
      const discount = this.items.reduce((acc, curr) => acc + curr.price, 0);
      return discount.toFixed(2);
    }
  },
  methods: {
    calculateRelativeDiscount(item) {
      const price = ((item.price || 0) / this.totalDiscount) * (this.discount || 0) || 0;
      return price.toFixed(2);
    },
    calculateResultingPrice(item) {
      return (item.price || 0) - this.calculateRelativeDiscount(item);
    },
    calculateDiscount() {
      // Calculate something if needed when the discount changes
    },
    addItem() {
      this.items.push({ name: "", price: null });
    },
    calculate3fuer2(item) {
      const price = item.price;
      let sorted = [...this.items3fuer2].sort((a, b) => b.price - a.price);
      const sum = this.items3fuer2.reduce((acc, curr) => acc + curr.price, 0);
      const discount = price/sum * (sorted[0].price + sorted[1].price);
      return discount?.toFixed(2);
    }
  }
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>
